<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between ">
          <div class="d-flex bordered qp-tab-nav">
            <div class="d-flex p-2">
              <template v-if="checkReadPermission($modules.packages.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/offers')">
                  <SvgIcon class="text-xl qp-tab-nav-is-active  " text="Packages">
                    <template v-slot:icon>
                      <PackagesIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.suggestions.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/suggestions')">
                  <SvgIcon class="text-xl text-thin-black  " text="Suggestions">
                    <template v-slot:icon>
                      <SuggestionsIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/cross-sale')">
                  <SvgIcon class="text-xl text-thin-black " text="Cross sale">
                    <template v-slot:icon>
                      <CrossSaleIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>
              <template v-if="checkWritePermission($modules.offers.management.slug)">
                <div class="nv_item d-flex p-4 pointer ">
                  <SvgIcon class="text-xl text-thin-black  " text="Configuration">
                    <template v-slot:icon>
                      <ConfigIcon/>
                    </template>
                  </SvgIcon>
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex gap-x-2">
            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="48"
                v-if="checkWritePermission($modules.packages.management.slug)"
                @click="addPackage"
            >
              <AddIcon/>
              <span class="ml-1">Add Package</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <div class="mt-4 pb-4" v-for="(activePackage, i) in packages" :key="i">

      <h3 class="heading_package">{{ activePackage.name }}</h3>
      <div class="d-flex align-center flex-wrap gap-x-8 gap-y-6">
        <v-card style="border-radius: 8px" class="shadow first_package_card">
          <v-card-title>
            Offer price
          </v-card-title>
          <v-card-text>
            <v-menu
                absolute
                content-class="q-menu"
                right
                top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :ripple="false"
                    absolute
                    class="text_capitalize"
                    elevation="0"
                    right
                    style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important; top:5px "
                    top
                    v-bind="attrs"
                    v-on="on"
                >
                  <DotsIcon/>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-if="checkWritePermission($modules.packages.management.slug)"
                    @click="editPackageFunc(activePackage.id)"
                >
                  <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                    <template #icon>
                      <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                    </template>
                  </SvgIcon>
                </v-list-item>
                <v-divider class="mb-2"/>
                <v-list-item v-if="checkDeletePermission($modules.packages.management.slug)"
                             @click="deletePackageFunc(activePackage.id)">
                  <SvgIcon text="Delete" class="red--text svg-stroke-red font-medium text-sm gap-x-2">
                    <template #icon>
                      <ActivateIcon/>
                    </template>
                  </SvgIcon>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-row>
              <v-col cols="12" class="mt-6 py-0">
                <div class="d-flex justify-space-between p-0 inner_text_first_package_card">
                  <p>Venues</p>
                  <p class="line-clamp-1 text-right">{{ getVenueCount(activePackage.products) }}</p>
                </div>
              </v-col>

              <!-- Products -->
              <v-col cols="12" class="py-0">
                <div class="d-flex justify-space-between p-0 inner_text_first_package_card">
                  <p>Products</p>
                  <p>{{ activePackage.products.length }}</p>
                </div>
              </v-col>

              <!-- Original Price -->
              <v-col cols="12" class="py-0">
                <div class="d-flex justify-space-between p-0 inner_text_first_package_card">
                  <p>Original Price</p>
                  <p>{{ getProductsSum(activePackage.products) |toCurrency }}</p>
                </div>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions class="first_package_card_footer">
            <v-col cols="12" class="py-0">
              <div class="d-flex justify-space-between p-0">
                <p>Offer Price</p>
                <p>{{ activePackage.offerPrice |toCurrency }}</p>
              </div>
            </v-col>
          </v-card-actions>
        </v-card>
        <Package
            v-for="(product,ind) in activePackage.products"
            :key="ind"
            :product="product"
            :index="i+1"
        />
        <div class="last_card d-flex justify-center align-center"
             v-if="checkWritePermission($modules.packages.management.slug)">
          <v-btn
              elevation="0"
              color="white"
              class="teal--text"
              fab
              tile
              @click="editPackageFunc(activePackage.id)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

        </div>
      </div>

    </div>

    <div class="  d-flex align-center justify-center" style="width: 250px; margin: 200px auto 0;"
         v-if="packages.length === 0">
      <v-col md="12">
        <v-img
            src="../../assets/images/offers_icons/no_offers_new.png"
        ></v-img>
      </v-col>
    </div>


    <PackageModal
        :showModal="packageModal"
        :id="packageId"
        :refreshForm="refreshForm"
        :editPackage="editPackage"
        @close="closePackageModal"
        @save="save"
    />
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>

  </v-container>
</template>
<script>
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import PackagesIcon from "@/assets/images/offers_icons/packages.svg";
import SuggestionsIcon from "@/assets/images/offers_icons/suggestions.svg";
import CrossSaleIcon from "@/assets/images/offers_icons/cross_sale.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import PackageModal from "@/components/Packages/PackageModal.vue";
import Package from "@/components/Packages/Package.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  name: "Offers.vue",
  components: {
    ActivateIcon,
    DotsIcon,
    EditIcon,
    Package, PackageModal, AddIcon, PackagesIcon, SuggestionsIcon, SvgIcon, ConfigIcon, CrossSaleIcon},
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      refreshForm:false,
      packageModal: false,
      packageId: null,
      editPackage: null,
      packages: [
        //   {
        //     name: 'Package 1',
        //     venueName: 'ZSC',
        //     originalPrice: 200,
        //     offerPrice: 100,
        //     products: [
        //       {
        //         venueId:4,
        //         venueName: 'Qasr Al Muwaiji',
        //         productType: 'Facility',
        //         name: 'Day Pass',
        //         price: 150,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //       {
        //         venueId:3,
        //         venueName: 'Al Ain palace museum',
        //         productType: 'Facility',
        //         name: 'Guided Tour',
        //         price: 200,
        //       },
        //     ]
        //   },
        //
        //   {
        //     name: 'Package 2',
        //     venueName: 'Al Qattara arts center',
        //     originalPrice: 450,
        //     offerPrice: 150,
        //     products: [
        //       {
        //         venueId:1,
        //         venueName: 'Bait Muhammad bin Khalifa',
        //         productType: 'Facility',
        //         name: 'Day Pass',
        //         price: 200,
        //       },
        //       {
        //         venueId:2,
        //         venueName: 'Jebel Hafeet desert park',
        //         productType: 'Event',
        //         name: 'Silver',
        //         price: 250,
        //       },
        //     ]
        //   }
        //

      ],
    }
  },
  mounted() {
    this.loadPackages();
  },
  computed: {},
  watch: {},
  methods: {
    confirmActions(data) {
      if (data.type == "delete") {
        this.confirmDelete(data.id)
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    confirmDelete(id){
      this.$http.get(`venues/offers/packages/delete-package/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.showSuccess(response.data.message);
              this.loadPackages()
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    deletePackageFunc(id){
      this.confirmModel = {
        id: id,
        title: "Do you want to delete this package?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    editPackageFunc(id) {
      this.$http.get(`venues/offers/packages/get-package/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let responseData = response.data.data;
              this.editPackage = responseData;
              this.packageModal = true;
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    getVenueCount(p) {
      const venueIdCounts = {};
      p.forEach(product => {
        const venueId = product.venueId;
        venueIdCounts[venueId] = (venueIdCounts[venueId] || 0) + 1;
      });
      return Object.keys(venueIdCounts).length
    },
    getProductsSum(p) {
      let sum = 0;
      p.forEach(product => {
        sum += product.price * product.quantity ;
      });
      return sum;
    },
    gotoPage(route) {
      this.$router.push(route);
    },
    closePackageModal() {
      this.packageId = null;
      this.editPackage = null;
      this.packageModal = false;
    },
    addPackage() {
      this.packageModal = true;
    },
    loadPackages(){
      this.showLoader("Saving package");
      this.$http.get(`venues/offers/packages/get-packages`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let responseData = response.data.data;

              this.packages = responseData.map(resPackage => ({
                id:resPackage.id,
                name: resPackage.package_name,
                venueName: resPackage.venue.name,
                originalPrice: resPackage.package_products.reduce((sum, product) => sum + parseFloat(product.offer_price), 0),
                offerPrice: resPackage.offer_price,
                products: resPackage.package_products.map(product => ({
                  venueId: product.venue_id,
                  venueName: product.venue_name,
                  productType: product.product_type,
                  name: product.product_name,
                  quantity: product.quantity,
                  offer_price: parseFloat(product.offer_price),
                  price: parseFloat(product.price),
                  calculated_price: parseFloat(product.price) * product.quantity,
                }))
              }));
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    save(data) {
      this.showLoader("Saving package");
      this.$http.post(`venues/offers/packages/save-package`,data)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
             this.loadPackages();
             this.closePackageModal();
             this.refreshForm = !this.refreshForm;
             this.$forceUpdate();
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
  }
}
</script>
<style scoped lang="scss">
.heading_package {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 4px;
}


.first_package_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px solid #4FAEAF;

  .v-card__title {
    color: #4FAEAF;
    padding: 10px;
    border-bottom: 1px solid #4FAEAF;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  .inner_text_first_package_card {
    font-size: 14px;
    line-height: 14.52px;
    color: black;
    text-align: left;
  }

  .first_package_card_footer {
    border-top: 1px solid #4FAEAF;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    padding-bottom: 20px;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.last_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px dashed #4FAEAF;
  border-radius: 1rem;
}
</style>